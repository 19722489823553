import React from "react";
import Form from "../Form";
import "./index.css";


export default function Main() {

  return (
    <>
      <div className="container-fluid ">

        <Form />

      </div>
    </>
  );
}
