import React, { useEffect, useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import UploadImg from "../../assets/upload_file.png";
import { upload } from "@testing-library/user-event/dist/upload";
import { Spin } from "antd";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import { getData } from "../../Utilities";
import { generateReport, gettingReportFromServer, uploadFileToVirusTotal } from "../../api";
import Alert from "@mui/material/Alert";
import axios from "axios";
import UseLocalStorage from "../../hooks/localstorage.hook";
import "./uploadFile.styles.css";
import { toast } from "react-toastify";
import { Progress } from 'antd';
import { FaFileUpload } from "react-icons/fa";

const { Dragger } = Upload;
const props = {
    name: "file",
    multiple: false,
};
function UploadFile({ setData }) {
    const [hash, setHash] = useState("");
    const [visibleImg, setVisibleImg] = useState(true);
    const [scanImg, setScanImg] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = React.useState("");
    const [loader, setLoader] = React.useState(false);
    const [successAlert, setSuccessAlert] = React.useState(true);
    const [alertContent, setAlertContent] = React.useState({});
    const [uploadFileStatus, setUploadFileStatus] = useState(false)

    const handleScanNew = () => {
        setProgress(0);
        setLoading(false);
        setThreat(-1);
        setScanImg(false);
        setName("");
        // setEmail("");
        setAlertContent({});
        setVisibleImg(true);
        // setEmailEntered(false);
        setCheckFile(false);
        setOriginalFile(null);
        setShowloadinBox(false);
        setShowResultBox(false);
        setUploadFileStatus(false)
    };
    const [threat, setThreat] = useState(-1);
    const [emailEntered, setEmailEntered] = useState(false);
    const [checkFile, setCheckFile] = useState(false);
    const [originalFile, setOriginalFile] = useState(null);
    const [showLoadingBox, setShowloadinBox] = useState(false);
    const [showResultBox, setShowResultBox] = useState(false);
    const [disabledTime, setDisabledTime, removeDisabledTime] = UseLocalStorage('disabledTime', null);
    const [remainingTime, setRemainingTime] = useState(null);
    const [lastClickedTime, setLastClickedTime, removeLastClickedTime] = UseLocalStorage("lastClickedTime", null)
    const [disabled, setDisabled, removeDisabled] = UseLocalStorage('buttonDisabled', false);
    const [fileList, setFileList] = useState([]);
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);
    const [progressCheck, setProgressCheck] = useState(false)
    const handleFileChange = async (file) => {
        const maxSizeInBytes = 200 * 1024 * 1024;
        if (file.file.size > maxSizeInBytes) {
            toast.error("File size exceeds the 200 MB limit")
            return; // Return if file size exceeds 650 MB
        }
        if (!file) {
            toast.error("File is required")
            return
        }
        setOriginalFile(file.file)
        setCheckFile(true)
        setName(file.file.name)
    };

    function isValidEmail(email) {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    }
    const onCancelUpload = () => {
        setCheckFile(false);
        setEmailEntered(true)
    }
    const onFileUpload = async () => {
        setProgress(0);
        setLoading(true);
        if (!originalFile) {
            toast.error("no file found");
            return
        }
        if (!email) {
            toast.error("email is required");
            return
        }
        if (!isValidEmail(email)) {
            toast.error("email is invalid");
            return
        }
        setDisabled(true);
        setShowloadinBox(true)
        setEmailEntered(true)
        setLastClickedTime(new Date().getTime())
        // setCheckFile(false)
        try {
            const response = await uploadFileToVirusTotal(originalFile, email);
            if (response === undefined) {
                toast.error("something went wrong while fetching your data")
                return
            }
            else {
                setData(response)
                setThreat(threat + response?.attributes?.stats?.malicious + response?.attributes?.stats?.suspicious)
                setShowResultBox(true)
                setShowloadinBox(false)
            }


        } catch (e) {
            console.log(e, "<==============")
            // alert("error while uploading data to the server")
        }
    }

    const LoadingBox = () => {
        return <div

            className="d-flex py-5 justify-content-center align-items-center"
        >
            <div className="text-center ">
                <div>
                    <h1 className="text-danger">
                        <strong>Scanning...</strong>
                    </h1>
                    <br />
                    <Spin size="large" className="custom-spinner" />
                </div>
                <div>
                    <br />
                    {/* <h3>Too many scans are currently in progress!</h3> */}
                    <h3>Please wait while your file is being scanned...</h3>
                </div>
                <div className="progress-container">
                    <Progress type="circle" percent={progress} strokeColor={"red"} />
                </div>
                <div>
                    <p className="scanning-note">Note:If you dont want to wait that long, the report will be send to your email within 5 minutes, DO NOT close the window.</p>
                </div>
            </div>
        </div>
    }

    const CompletedBox = () => {
        return <div
            style={{
                // backgroundColor:
                //     alertContent.severity === "error" ? "#f5ecec" : "#caebdb",
                borderRadius: ".75rem",
                height: "100%",
            }}
            className="d-flex py-5 justify-content-center align-items-center"
        >
            <div className="text-center ">
                {threat > -1 ? (
                    <div>
                        <h3 className="text-danger">
                            <strong>Potential Threats Found</strong>
                        </h3>
                    </div>
                ) : <div> <h3 className="text-success">
                    {/* <strong>No Threats Detected</strong> */}
                </h3></div>}
                <div>
                    <br />
                    <h3> Successful</h3>
                    <p>Kindly check your email for detailed report</p>
                    <br />

                    <button
                        style={{ backgroundColor: "red", borderColor: "red" }}
                        className="btn btn-primary"
                        onClick={handleScanNew}
                    >
                        Scan New File
                    </button>
                </div>
            </div>
        </div>
    }


    useEffect(() => {
        if (disabled) {
            const now = new Date().getTime();
            // setDisabledTime(now);
            const timer = setInterval(() => {
                const elapsedTime = now - lastClickedTime;
                const timeLeft = 180000 - elapsedTime;
                if (timeLeft <= 0) {
                    setDisabled(false);
                    removeDisabledTime();
                    setRemainingTime(null);
                    removeLastClickedTime();
                    clearInterval(timer);
                } else {
                    setRemainingTime(timeLeft);
                }
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [disabled, disabledTime, setDisabledTime, removeDisabledTime]);


    useEffect(() => {
        if (!loading) return;

        const totalTime = 15 * 60 * 1000; // total time in milliseconds
        const fastPart = 2 * 60 * 1000;   // fast part time in milliseconds (90% in 5 minutes)
        const slowPart = totalTime - fastPart; // remaining time (10% in 10 minutes)
        const fastIncrement = 90 / fastPart; // 90% spread over 5 minutes
        const slowIncrement = 10 / slowPart; // 10% spread over 10 minutes
        const start = Date.now();
        const interval = setInterval(() => {
            const elapsed = Date.now() - start;
            let newProgress;
            if (elapsed < fastPart) {
                newProgress = Math.min(90, (elapsed * fastIncrement).toFixed(2));
            } else {
                const slowElapsed = elapsed - fastPart;
                newProgress = Math.min(100, (90 + slowElapsed * slowIncrement).toFixed(2));
            }

            setProgress(newProgress);

            if (newProgress >= 100) {
                clearInterval(interval);
                setLoading(false);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [loading]);

    return (
        <>
            {emailEntered && (showLoadingBox === true || showResultBox === true) === true ? null : <div className="text-center ">
                <div>
                    <br />
                    <h3> Enter your email and upload your file </h3>
                    <br />

                    <div className="email-and-upload-image-box">
                        <div className="email-div">
                            <TextField
                                label="Email"
                                id="outlined-size-small"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                // size="small"
                                style={{ width: "70%" }}
                                // fullWidth
                                required
                                type="email"
                            />
                        </div>
                        <Dragger
                            style={{ color: "#FF0000", borderColor: "#FF0000", borderStyle: "none", display: "flex", gap: 5 }}
                            {...props}
                            onChange={handleFileChange}
                            multiple={false}
                            listType="none"
                            showUploadList={false}
                        >
                            <div className="image-upload-div">
                                {/* <img className="paper-image" src={UploadImg} alt="box uploading" />
                                 */}
                                <FaFileUpload className="paper-image" style={{ fontSize: "200px" }} />
                                <div className="mt-3" style={{ padding: "16px" }}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined style={{ color: "#FF0000" }} />
                                    </p>

                                    <p className="ant-upload-hint">Max File Size of 200 MB.</p>
                                    <p className="ant-upload-text">
                                        Drop your files to scan for viruses
                                    </p>
                                    <button style={{ background: "#FF0000", borderColor: "#FF0000" }} className="btn btn-primary">SELECT FILE</button>
                                </div>
                            </div>
                        </Dragger>
                        {name ? <p className="text-center">File Name: {name}</p> : null}

                        <Button
                            variant="contained"
                            className="btn btn-primary upload-button"

                            // type="submit"
                            style={{
                                backgroundColor: "red",
                                color: "white",
                            }}
                            onClick={onFileUpload}
                        >
                            {loader ? <Spin size="medium" /> : "Upload File"}
                        </Button>
                    </div>

                    <br />
                    {/* <button
                    className="btn btn-primary rounded-pill"
                    onClick={handleScanNew}
                >
                    Upload File
                </button> */}
                </div>
            </div>}
            {showLoadingBox === true && showResultBox === false && emailEntered === true ? <LoadingBox /> : null}
            {/* <LoadingBox /> */}
            {showResultBox && !showLoadingBox ? <CompletedBox /> : null}
        </>
    )
}

export default UploadFile